<template src="./EventAdd.html"></template>

<script>
import PickerMap from "@/components/picker/PickerMap";
//import user from "@/store/modules/user";
export default {
  name: "EventAdd",
  components: {PickerMap},
  data: () => ({

    res:"",
    loading: false,

    dialog:false,

    valid: true,
    show_id:0,
    layout_id: 0,
    layout_pm:0,
    layout_am:0,
    date:"",
    time:null,
    prices:[],
    types:[],


    zone_count:0,
    only_entry: false,
    entry_message: "",

    price_colors:['black', 'red', 'blue', 'green', 'purple'],

    event_ready: false,
    event: {},

    more: false,

    showlist:[],
    showlist_ready:false,

    layouts:[],
    layouts_ready:false,

    layout_PMs:[],
    layouts_PM_ready:false,

    layout_AMs:[],
    layouts_AM_ready:false,

    dates: [],
    menu_date: false,

    menu_time: false,

    ticket_type:[],




  }),

  // computed: {
  //
  //   layout_id: function () {
  //
  //     if (this.selected>-1){
  //       return this.list[this.selected].id;
  //     }
  //     return false;
  //   },
  //
  // },

  created() {

    if (this.$store.getters.isAuthenticated){

      this.loadShows();
      this.loadLayouts();
      this.loadTicketType_mock();

    }


  },


  methods:{

    openPicker(mode){

      this.dialog = true;

      this.$nextTick(() => {


        this.$refs.picker.preparePicker({

          type: 'adm',
          mode:mode,

          layout_id:this.layout_id,
          layout_pm:this.layout_pm,
          layout_am:this.layout_am,

        });


      });


    },

    onReturnDialog(data){
      //console.log(data);

      switch (data.mode){
        case 'sp':
          this.layout_id = data.layout_id;
          this.loadLayoutMaps();
          break;
        case 'pm':
          this.layout_pm  = data.layout_pm;
          this.zone_count = data.zone_count;
          this.only_entry = Boolean(data.only_entry);

          if (this.only_entry){
            this.entry_message = "Билет на проход"
          }
          else{
            this.entry_message = ""
          }

          break;
        case 'am':
          this.layout_am = data.layout_am;
          break;
      }


    },

    onChangeSelectPM(e){

      let t = this.layout_PMs.find(item=>item.id===e);

      this.zone_count = t.zone_count;
      this.only_entry = Boolean(t.only_entry);

      this.types = [];
      //this.types = new Array(this.zone_count);


      if (this.only_entry){

        for (let i = 0; i < this.zone_count; i++) {

          console.log(i);
          console.log(this.ticket_type[i].name);

          this.types.push(this.ticket_type[i].id);

        }
      }




      //this.ticket_type.forEach(tt=>{this.types.push(tt.id)})



      if (this.only_entry){
        this.entry_message = "Билет на проход"
      }
      else{
        this.entry_message = ""
      }

    },

    loadShows(){
      let pr = this.$store.state.api.loadShowList();
      pr.then( data =>{
        this.showlist = data;

        this.showlist.sort((a,b)=>{

          if (a.name < b.name){
            return -1;
          }
          if (a.name > b.name){
            return 1;
          }
          return 0;
        })

        this.showlist_ready = true;
      })
    },

    // брать из layout.json ticket_type и спрашивать в global base наименования
    loadTicketType_mock(){

      this.ticket_type = this.$store.getters.getTicketTypes;

    },


    loadLayouts(){

      this.layouts = this.$store.getters.getLayouts;
      this.layouts_ready = true;

    },


    loadLayoutMaps(){

      this.only_entry = false;
      this.zone_count = 0;
      this.layout_pm = 0;
      this.layout_am = 0;
      this.loadLayoutPM();
      this.loadLayoutAM();
    },


    loadLayoutPM(){

      let pr = this.$store.state.api.loadLayout_PM_list(this.layout_id);
      pr.then( data =>{
        this.layout_PMs = data.seat_zones;
        this.layouts_PM_ready = true;
      })
    },

    loadLayoutAM(){

      let pr = this.$store.state.api.loadLayout_AM_list(this.layout_id);
      pr.then( data =>{
        this.layout_AMs = data.seat_armour;
        this.layouts_AM_ready = true;
      })
    },


    addEvent(){

      // TODO заблочить кнопку отправки
      this.loading = true;

      let prices = "";

      this.prices.forEach(price=>{ prices += "/"+price });
      prices = prices.substring(1);

      let types = "";

      this.types.forEach(type=>{ types += "/"+type });
      types = types.substring(1);

      let post_data = {

        id:0,
        name:this.showlist.find(item=>item.id===this.show_id).name,
        show_id: this.show_id,
        timedate:this.date + ' ' + this.time + ':00',
        lay_id:this.layout_id,
        lay_pm_id:this.layout_pm,
        lay_am_id:this.layout_am,
        prices:prices,
        ticket_types:types,
        status:31,
        free_seat:"15",
        type: this.only_entry ? 1 : 0
      }
      console.log(post_data);

      let pr2 = this.$store.state.api.addEvent(post_data);

      pr2.then( data =>{
        console.log(data);

        this.res = "Мероприятие "+data+" добавлено";
        this.loading = false;
      })

    },




  }
}
</script>

<style scoped>

</style>
