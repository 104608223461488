<template>


    <v-container style="background-color:#ffecec">
      <v-row v-if="list_ready"
          no-gutters
      >
        <v-col
            cols="4"
            class="flex-grow-0 flex-shrink-1"
        >




          <v-card
              class="mx-auto"
              max-width="500"
          >

            <v-list two-line>
              <v-list-item-group
                  v-model="selected"
                  active-class="orange--text"
              >
                <template v-for="(item, index) in list">
                  <v-list-item :key="item.id"
                  @click="listClick(item)">
                    <template>
                      <v-list-item-content>
                        <v-list-item-title v-text="item.name"></v-list-item-title>

                      </v-list-item-content>

                      <v-list-item-action>
                        <v-list-item-action-text v-text="item.seat_total"></v-list-item-action-text>
                      </v-list-item-action>
                    </template>
                  </v-list-item>

                  <v-divider
                      v-if="index < list.length - 1"
                      :key="index+'-line'"
                  ></v-divider>
                </template>
              </v-list-item-group>
            </v-list>
          </v-card>







        </v-col>

        <v-col
        cols="6"
        style="min-width: 100px; max-width: 100%; background-color:blue"
        class="flex-grow-1 flex-shrink-1"
        >
          <Hall type="adm" mode="base" ref="hall"/>
        </v-col>

      </v-row>
    </v-container>

</template>

<script>
import Hall from "@/components/hall/Hall/Hall";

export default {
  name: "PickerMap",
  // eslint-disable-next-line vue/no-unused-components
  components: {Hall},

  data: () => ({

    options:{

      type: "adm",
      mode: "sp",

      layout_id: 0,
      layout_pm: 0,
      layout_am: 0,

    },

    // sp pm am
    type: "adm",
    mode: "sp",

    selected: 3,

    layout_id:0,
    layout_pm:0,
    layout_am:0,

    zone_count:0,
    only_entry: false,


    lay_permitted_list:[],
    list:[],
    list_ready: false,

  }),

  // computed: {
  //
  //   layout_id: function () {
  //
  //     if (this.selected>-1){
  //       return this.list[this.selected].id;
  //     }
  //     return false;
  //   },
  //
  // },


  created() {

    if (this.$store.getters.isAuthenticated){

      this.preparePicker(this.options);
    }

  },


  methods:{

    returnValues(){

      this.$emit('returnDialog', {

        type: this.type,
        mode: this.mode,

        layout_id: this.layout_id,
        layout_pm: this.layout_pm,
        layout_am: this.layout_am,

        zone_count: this.zone_count,
        only_entry: Boolean(this.only_entry),

      })

    },

    clearVar(options){

      //console.log(options);

      this.selected = null;

      if (options.mode.length>0){
        this.mode = options.mode;
      }

      if (options.type.length>0){
        this.type = options.type;
      }

      if (options.layout_id>0){
        this.layout_id = options.layout_id;
      }

      if (options.layout_pm>0){
        this.layout_pm = options.layout_pm;
      }

      if (options.layout_am>0){
        this.layout_am = options.layout_am;
      }


    },

    preparePicker(options){

      //console.log('pp');

      this.clearVar(options);


      switch (this.mode){

        case 'sp':

          this.loadLayoutList();

          // pr = this.$store.state.api.loadPermittedLayout(user.state.selectel_path);
          //
          // pr.then( data =>{
          //
          //   this.lay_permitted_list   = data.layout;
          //   this.loadLayoutList();
          //
          // })
          break

        case 'pm':

          //this.layout_id = 28;
          this.loadLayoutPMList();


          break;

        case 'am':

          //this.layout_id = 28;
          this.loadLayoutAMList();



          break;


        default:

          this.mode = "fake";
          break;
      }

      // грузим карту по умолчанию, а тут из кресел надо что-то веселое изобразить - шляпу
      this.list_ready = true;

      this.$nextTick(() => {

        let data = {

          type: 'adm',
          mode: this.mode,

          view:"",
          options:{},

          layout_id:this.layout_id,
          layout_pm:this.layout_pm,
          layout_am:this.layout_am,
          scale:0.5

        }

        //console.log(data);

        this.$refs.hall.prepareHall(data);

      })




    },


    listClick(item){

      //console.log(id);

      switch (this.mode){
        case "sp":
          this.layout_id = item.id;
          break;

        case "pm":
          this.layout_pm = item.id;
          this.zone_count = item.zone_count;
          this.only_entry = item.only_entry;
          break;

        case "am":
          this.layout_am = item.id;
          break;
      }

      let data = {

        type: 'adm',
        mode: this.mode,

        view:'',
        options:{},

        layout_id:this.layout_id,
        layout_pm:this.layout_pm,
        layout_am:this.layout_am,
        scale:0.3

      }

      //console.log(data);

      this.$refs.hall.prepareHall(data);

      this.returnValues();

    },


    onload: function(){
      console.log('onload')
    },

    loadLayoutList: function(){

      this.list = [];
      this.list = this.$store.getters.getLayouts;
      this.list_ready = true;

      if (this.layout_id>0){
        this.selected = this.list.findIndex(item=>item.id === this.layout_id);
      }

      // this.list = [];
      // let pr = this.$store.state.api.loadLayouts(this.lay_permitted_list);
      //
      // pr.then( data =>{
      //   this.list = data;
      //   this.list_ready = true;
      //
      //   if (this.layout_id>0){
      //     this.selected = this.list.findIndex(item=>item.id === this.layout_id);
      //   }
      //
      // })

    },

    loadLayoutPMList: function(){

      let pr = this.$store.state.api.loadLayout_PM_list(this.layout_id);

      pr.then( data =>{
        this.list = data.seat_zones;
        this.list_ready = true;


        if (this.layout_id>0){
          if (this.layout_pm>0){
            this.selected = this.list.findIndex(item=>item.id === this.layout_pm);
          }
        }

      })

    },

    loadLayoutAMList: function(){

      let pr = this.$store.state.api.loadLayout_AM_list(this.layout_id);

      pr.then( data =>{
        this.list = data.seat_armour;
        this.list_ready = true;

        if (this.layout_id>0){
          if (this.layout_am>0){
            this.selected = this.list.findIndex(item=>item.id === this.layout_am);
          }
        }
      })

    },




  }
}
</script>

<style scoped>

</style>
